import * as THREE from 'three'

import { CubeCamera, Decal, useGLTF, useTexture } from '@react-three/drei'

import React, { useLayoutEffect } from 'react'

const Z_Decal = ( props ) => {

	const logo       = useTexture( '/ThreeJS_Files/Square.png' )
	logo.flipY       = false
	logo.needsUpdate = true

	return (
			<Decal
					{ ...props }
					map={ logo }

					// debug
			/>
	)

}

const Car = React.forwardRef( ( {
	                                color,
	                                showLogo,
	                                setSelectedColor,
	                                ...props
                                }, ref ) => {
	React.useImperativeHandle( ref, () => ( {
		                           setColor( color ) {
			                           materials.paint.color.set( color )
		                           },
	                           } ),
	)

	const {
		      nodes,
		      materials,
	      } = useGLTF( '/ThreeJS_Files/porsche-transformed.glb' )


	//region Layout effect
	useLayoutEffect( () => {
		materials.paint.color.set( color )
		materials.paint.metalness                   = 0.2
		materials.paint.roughness                   = 0
		materials.paint.clearcoat                   = 1
		materials.paint.envMapIntensity             = 1.5
		materials.paint.aoMapIntensity              = 1.5
		materials.paint.roughnessMapIntensity       = 2
		materials[ '930_chromes' ].metalness        = 1
		materials[ '930_chromes' ].roughness        = 0.3
		materials[ '930_chromes' ].color            = new THREE.Color( 'white' )
		materials[ 'glass' ].color                  = new THREE.Color( 'white' )
		materials[ 'glass' ].opacity                = 0.6
		materials[ '930_plastics' ].roughness       = 0.8
		materials[ '930_lights' ].emissiveMap       = materials[ '930_lights' ].map
		materials[ '930_lights' ].emissiveIntensity = 50
		materials[ '930_tire' ].color.set( 'black' )
		materials[ '930_tire' ].roughness = 0.7
	}, [ materials ] )
	//endregion

	//region Change color on click
	const getColor = () => {
		return (
				'#' + Math.random()
				          .toString( 16 )
				          .slice( 2, 8 )
		)
	}

	const handleCarClick = () => {
		setSelectedColor( getColor() )
	}
	//endregion

	return (
			<group { ...props }
			       dispose={ null }
			       onClick={ handleCarClick }
			>
				<CubeCamera frames={ 1 }
				            position={ [
					            0,
					            1.49,
					            0,
				            ] }
				            near={ 0.00000001 }
				            resolution={ 128 }>
					{ ( texture ) => (
							<group position={ [
								0,
								-1.5,
								0,
							] }>
								{/*Paint*/ }
								<group rotation={ [
									Math.PI / 2,
									0,
									0,
								] }>
									<mesh
											geometry={ nodes.mesh_1_instance_0.geometry }
											material={ materials[ '930_plastics' ] }
											position={ [
												-7.966238,
												-0.10155,
												-7.966238,
											] }
											scale={ 0.000973 }
									/>
									<mesh
											geometry={ nodes.mesh_1_instance_1.geometry }
											material={ materials[ '930_plastics' ] }
											position={ [
												-7.966238,
												-0.10155,
												-7.966238,
											] }
											scale={ 0.000973 }
									/>
								</group>
								{/*Car*/ }
								<group position={ [
									-7.966238,
									-0.10155,
									-7.966238,
								] }
								       scale={ 0.000973 }>

									<mesh geometry={ nodes.mesh_0.geometry }
									      material={ materials.paint }
									      material-envMap={ texture }

									      dispose={ null }
									>
										{/*region Decals*/ }
										{ showLogo &&
										  <>
											  {/*Left*/ }
											  <Z_Decal position={ [
												  9000,
												  720,
												  8500,
											  ] }

											           rotation={ [
												           -Math.PI / 2,
												           -Math.PI / 2,
												           Math.PI / 2,
											           ] }

											           scale={ 600 }
											  />
											  {/*Right*/ }
											  <Z_Decal position={ [
												  7500,
												  720,
												  8500,
											  ] }

											           rotation={ [
												           Math.PI / 2,
												           Math.PI / 2,
												           Math.PI / 2,
											           ] }

											           scale={ 600 }
											  />
											  {/*Bonnet*/ }
											  <Z_Decal position={ [
												  8200,
												  1000,
												  10000,
											  ] }

											           rotation={ [
												           Math.PI / 2,
												           0,
												           0,
											           ] }

											           scale={ 1000 }
											  />
										  </>
										}
										{/*endregion*/ }
									</mesh>
									<group name={ 'other_stuff' }>
										<mesh geometry={ nodes.mesh_0_1.geometry }
										      material={ materials[ '930_chromes' ] } />
										<mesh geometry={ nodes.mesh_0_2.geometry }
										      material={ materials.black } />
										<mesh geometry={ nodes.mesh_0_3.geometry }
										      material={ materials[ '930_lights' ] } />
										<mesh geometry={ nodes.mesh_0_4.geometry }
										      material={ materials.glass } />
										<mesh geometry={ nodes.mesh_0_5.geometry }
										      material={ materials[ '930_stickers' ] } />
										<mesh geometry={ nodes.mesh_0_6.geometry }
										      material={ materials[ '930_plastics' ] }
										      material-polygonOffset
										      material-polygonOffsetFactor={ -10 } />
										<mesh geometry={ nodes.mesh_0_7.geometry }
										      material={ materials[ '930_lights_refraction' ] } />
										<mesh geometry={ nodes.mesh_0_8.geometry }
										      material={ materials[ '930_rim' ] } />
										<mesh geometry={ nodes.mesh_0_9.geometry }
										      material={ materials[ '930_tire' ] } />
									</group>
								</group>
							</group>
					) }
				</CubeCamera>
			</group>
	)
} )

useGLTF.preload( '/ThreeJS_Files/porsche-transformed.glb' )

export default Car
