import React, {Suspense, useEffect, useRef, useState}          from 'react'
import './App.sass'
import {Canvas}                                                from '@react-three/fiber'
import {Environment, Loader, OrbitControls, PerspectiveCamera} from '@react-three/drei'
import Car                                                     from './Car'
import {Color}                                                 from 'three'

function App() {
	const car = useRef()

	//region Color
	const [selectedColor, setSelectedColor] = useState(new Color(0xffffff))

	useEffect(() => {
		car?.current?.setColor(selectedColor)
	}, [selectedColor])
	//endregion

	//region Rotation
	const [rotation, setRotation] = useState(false)
	//endregion

	//region Show logo
	const [showLogo, setShowLogo] = useState(true)
	//endregion

	return (
		<>
			<div className='hud'>
				<label>
					<span className='title'>Couleur de la voiture :</span>
					<input
						type='color'
						value={selectedColor}
						onChange={(e) => setSelectedColor(e.target.value)}
					/>
				</label>

				<label>
					<span className='title'>Pivotage automatique ?</span>
					<input
						type='checkbox'
						checked={rotation
						         ? 'checked'
						         : ''}
						onChange={() => {
							setRotation(!rotation)
						}}
					/>
				</label>

				<label>
					<span className='title'>Afficher le logo ?</span>
					<input
						type='checkbox'
						checked={showLogo
						         ? 'checked'
						         : ''}
						onChange={() => {
							setShowLogo(!showLogo)
						}}
					/>
				</label>
			</div>
			<Canvas
				gl={{toneMappingExposure: 0.7}}
			>
				<Z_Environment/>

				<spotLight
					angle={1}
					position={[
						-80,
						200,
						-100,
					]}
					intensity={1}
				/>

				<Suspense>
					<Car
						ref={car}
						color={selectedColor}
						showLogo={showLogo}
						setSelectedColor={setSelectedColor}
						position={[
							-8,
							0,
							-2,
						]}
						scale={20}
						rotation-y={-Math.PI / 4}
					/>
				</Suspense>
				<OrbitControls
					enableZoom={true}
					enablePan={true}
					minPolarAngle={0}
					maxPolarAngle={Math.PI / 2.25}
					makeDefault

					autoRotate={rotation}
					autoRotateSpeed={3}
				/>
				<PerspectiveCamera
					makeDefault
					position={[
						150,
						49,
						12,
					]}
					fov={35}
				/>
			</Canvas>
			<Loader/>
		</>

	)
}

const Z_Environment = () => {

	return (
		<group name={'environment'}>
			<ambientLight intensity={.5}/>

			<Suspense fallback={null}>
				<Environment
					path={'/ThreeJS_Files/tresor/'}
					files={[
						'front.jpg',      // PX
						'back.jpg',       // NX
						'top.jpg',        // PY
						'bottom.jpg',     // NY
						'left.jpg',       // PZ
						'right.jpg',      // NZ
					]}
					//				files={'/ThreeJS_Files/old_depot_2k.hdr'}
					ground={{
						height: 32,
						radius: 200,
					}}
					resolution={2 ** 6}
				/>
			</Suspense>
		</group>
	)
}

export default App
